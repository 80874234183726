var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasMealPlanCategories)?_c('div',{staticClass:"c-mealplan-widget u-flex u-flex-align-center u-flex-justify-center u-themeable-bg",class:{
    'has-overlay': _vm.model.addDarkOverlay,
    'o-content-box': !_vm.model.isSmall,
    'is-small-widget': _vm.model.isSmall
  },style:({
    '--bg-color': _vm.model.backgroundColor
  })},[(_vm.showBackgroundImage)?_c('Picture',{staticClass:"c-mealplan-widget__picture",attrs:{"src":_vm.model.backgroundImage,"resizer":_vm.model.imageResizer.name,"alt":_vm.model.kicker,"mobile":[375, 300],"tablet":[768, 300],"desktop":[992, 300],"wide":[1600, 300],"options":{ mode: 'max' }}}):_vm._e(),(_vm.model.backgroundImageMobile && (_vm.$mq == 'mobile' || _vm.model.isSmall))?_c('Picture',{staticClass:"c-mealplan-widget__picture",attrs:{"src":_vm.model.backgroundImageMobile,"resizer":_vm.model.imageResizer.name,"alt":_vm.model.kicker,"mobile":[375, 300],"options":{ mode: 'max' }}}):_vm._e(),_c('div',{staticClass:"c-mealplan-widget__content u-flex u-flex-direction-column u-flex-align-center u-text-align--center",class:{
      'u-themeable-color-dark': _vm.model.foregroundColor == 'dark',
      'u-themeable-color-light': _vm.model.foregroundColor == 'light'
    }},[_c('strong',{staticClass:"c-label--m  u-opacity-70 u-mb--s"},[_vm._v(_vm._s(_vm.model.kicker))]),_c('div',{staticClass:"c-mealplan-widget__text u-font-bold u-mb--m u-mt--xs ",class:_vm.textClass},_vm._l((_vm.processedHtml),function(item,index){return _c('span',{key:index},[(item.startsWith('0}'))?[_c('Dropdown',{attrs:{"items":_vm.days,"small-arrow":true,"icon-color":_vm.iconColor},model:{value:(_vm.day),callback:function ($$v) {_vm.day=$$v},expression:"day"}}),_c('span',{staticClass:"u-opacity-70"},[_vm._v(" "+_vm._s(item.replace('0}', ''))+" ")])]:(item.startsWith('1}'))?[_c('Dropdown',{attrs:{"items":_vm.predefinedSelector,"small-arrow":true,"icon-color":_vm.iconColor},model:{value:(_vm.selectedMealPlan),callback:function ($$v) {_vm.selectedMealPlan=$$v},expression:"selectedMealPlan"}}),_c('span',{staticClass:"u-opacity-70"},[_vm._v(" "+_vm._s(item.replace('1}', ''))+" ")])]:(item.startsWith('2}'))?[_c('Dropdown',{attrs:{"items":_vm.portions,"small-arrow":true,"icon-color":_vm.iconColor},model:{value:(_vm.portion),callback:function ($$v) {_vm.portion=$$v},expression:"portion"}}),_c('span',{staticClass:"u-opacity-70"},[_vm._v(" "+_vm._s(item.replace('2}', ''))+" ")])]:[_c('span',{staticClass:"u-opacity-70"},[_vm._v(_vm._s(item))])]],2)}),0),_c('a',{ref:"button",staticClass:"c-button u-flex-shrink-0 u-text-align--center",class:{
        'c-button--dark': _vm.model.foregroundColor == 'dark',
        'c-button--dark-inverted u-themeable-color': _vm.model.foregroundColor == 'light',

      },attrs:{"href":_vm.href},on:{"click":_vm.trackCreateMealPlan}},[_c('Icon',{staticClass:"u-mr--xs",class:{
          'u-themeable-stroke': _vm.model.foregroundColor == 'light',
          'c-icon-stroke-white': _vm.model.foregroundColor == 'dark'
        },attrs:{"icon":_vm.iconRefreshArrows,"size":16}}),_vm._v(" "+_vm._s(_vm.model.buttonText)+" ")],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }