<template>
  <div
    v-if="hasMealPlanCategories"
    class="c-mealplan-widget u-flex u-flex-align-center u-flex-justify-center u-themeable-bg"
    :class="{
      'has-overlay': model.addDarkOverlay,
      'o-content-box': !model.isSmall,
      'is-small-widget': model.isSmall
    }"
    :style="{
      '--bg-color': model.backgroundColor
    }"
  >
    <Picture
      v-if="showBackgroundImage"
      class="c-mealplan-widget__picture"
      :src="model.backgroundImage"
      :resizer="model.imageResizer.name"
      :alt="model.kicker"
      :mobile="[375, 300]"
      :tablet="[768, 300]"
      :desktop="[992, 300]"
      :wide="[1600, 300]"
      :options="{ mode: 'max' }"
    />
    <Picture
      v-if="model.backgroundImageMobile && ($mq == 'mobile' || model.isSmall)"
      class="c-mealplan-widget__picture"
      :src="model.backgroundImageMobile"
      :resizer="model.imageResizer.name"
      :alt="model.kicker"
      :mobile="[375, 300]"
      :options="{ mode: 'max' }"
    />
    <div
      class="c-mealplan-widget__content u-flex u-flex-direction-column u-flex-align-center u-text-align--center"
      :class="{
        'u-themeable-color-dark': model.foregroundColor == 'dark',
        'u-themeable-color-light': model.foregroundColor == 'light'
      }"
    >
      <strong class="c-label--m  u-opacity-70 u-mb--s">{{ model.kicker }}</strong>
      <div
        class="c-mealplan-widget__text u-font-bold u-mb--m u-mt--xs "
        :class="textClass"
      >
        <span
          v-for="(item, index) in processedHtml"
          :key="index"
        >
          <template v-if="item.startsWith('0}')">
            <Dropdown
              v-model="day"
              :items="days"
              :small-arrow="true"
              :icon-color="iconColor"
            />
            <span class="u-opacity-70">
              {{ item.replace('0}', '') }}
            </span>
          </template>
          <template v-else-if="item.startsWith('1}')">
            <Dropdown
              v-model="selectedMealPlan"
              :items="predefinedSelector"
              :small-arrow="true"
              :icon-color="iconColor"
            />
            <span class="u-opacity-70">
              {{ item.replace('1}', '') }}
            </span>
          </template>
          <template v-else-if="item.startsWith('2}')">
            <Dropdown
              v-model="portion"
              :items="portions"
              :small-arrow="true"
              :icon-color="iconColor"
            />
            <span class="u-opacity-70">
              {{ item.replace('2}', '') }}
            </span>
          </template>
          <template v-else>
            <span class="u-opacity-70">{{ item }}</span></template>
        </span>
      </div>
      <a
        ref="button"
        class="c-button u-flex-shrink-0 u-text-align--center"
        :class="{
          'c-button--dark': model.foregroundColor == 'dark',
          'c-button--dark-inverted u-themeable-color': model.foregroundColor == 'light',

        }"
        :href="href"
        @click="trackCreateMealPlan"
      >
        <Icon
          class="u-mr--xs"
          :class="{
            'u-themeable-stroke': model.foregroundColor == 'light',
            'c-icon-stroke-white': model.foregroundColor == 'dark'
          }"
          :icon="iconRefreshArrows"
          :size="16"
        />
        {{ model.buttonText }}
      </a>
    </div>
  </div>
</template>
<script>
import iconRefreshArrows from '@ds/svg/icons/stroke/refresh-arrows.svg'
import Dropdown from '@/CVI/WebCore/components/Dropdown.vue'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Picture from '@/CVI/WebCore/components/Picture.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon,
    Dropdown,
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconRefreshArrows,
      day: this.model.defaultDaysFilterValue,
      portion: this.model.defaultPortionsFilterValue,
      selectedMealPlan: this.model.mealPlanCategories[0].id,
      maxDays: 20,
      maxPortions: 20
    }
  },
  computed: {
    processedHtml() {
      const str = this.model.text.split('{')
      return str
    },
    hasMealPlanCategories() {
      return this.model.mealPlanCategories.filter(item => item).length > 0
    },
    predefinedSelector() {
      return this.model.mealPlanCategories
        .map(({ id, heading }) => ({ id, name: heading }))
    },
    portions() {
      const array = [...Array(this.maxPortions).keys()]
      return array.map(item => ({
        id: item + 1,
        name: item + 1 > 1 ? `${item + 1} ${this.model.portionsPluralText}` : `${item + 1} ${this.model.portionsSingularText}`
      }))
    },
    days() {
      const array = [...Array(this.maxDays).keys()]
      return array.map(item => ({
        id: item + 1,
        name: item + 1 > 1 ? `${item + 1} ${this.model.daysPluralText}` : `${item + 1} ${this.model.daysSingularText}`
      }))
    },
    textClass() {
      if (this.$mq == 'desktop' && !this.model.isSmall) {
        return 'u-font-size-h3'
      }
      return 'u-font-size-h4'
    },
    href() {
      const { url } = this.model.mealPlanCategories.find(({ id }) => id == this.selectedMealPlan)
      return `${url}?days=${this.day}&portions=${this.portion}`
    },
    iconColor() {
      return this.model.foregroundColor == 'dark' ? 'dark-gray' : 'white'
    },
    activeHeading() {
      const { name } = this.predefinedSelector.find(({ id }) => id == this.selectedMealPlan)
      return name
    },
    showBackgroundImage() {
      return this.model.backgroundImage && !this.model.isSmall && (this.$mq == 'desktop' || this.$mq == 'tablet' || !this.model.backgroundImageMobile)
    }
  },
  watch: {
    day(value) {
      tracker.track({
        dayPickerSelect: `${value} day`,
        event: 'mealplanWidgetDayPicker'
      })
    },
    portion(value) {
      tracker.track({
        peoplePickerSelect: `${value} people`,
        event: 'mealplanWidgetPeoplePicker'
      })
    },
    selectedMealPlan() {
      tracker.track({
        mealPickerSelect: this.activeHeading,
        event: 'mealplanWidgetMealPicker'
      })
    }
  },
  methods: {
    trackCreateMealPlan() {
      tracker.track({
        dayPickerSelect: `${this.day} day`,
        peoplePickerSelect: `${this.portion} people`,
        mealPickerSelect: this.activeHeading,
        event: 'mealplanWidgetCreateMealplan'
      })
    }
  }
}
</script>

<style lang="scss">
@use '../../scss/mealplan-widget.scss';
</style>
