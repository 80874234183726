<template>
  <div
    class="c-dropdown"
    :class="classes"
  >
    <Popover
      v-if="$mq == 'desktop'"
      v-model="isDropdownVisible"
      :placement="'bottom-start'"
      :offset="2"
      :auto-close="true"
      :auto-close-delay="100000"
      :container="container"
      dropdown-class="c-dropdown__dropdown"
    >
      <span
        class="c-dropdown__text u-flex u-flex-align-center"
        :class="{
          'u-font-size-medium': size === 'small'
        }"
        @click.prevent="toggleDropdown"
      >
        <span
          :class="{
            'u-font-italic': activeItem && activeItem.isMarked,
            'u-mr--xxs': smallArrow,
            'u-mr--s': !smallArrow
          }"
        >
          {{ activeName }}
        </span>
        <Icon
          class="u-ml--a"
          :icon="iconArrowDown"
          :size="arrowSize"
          :fill="iconColor"
        />
      </span>
      <template slot="popover">
        <div class="c-dropdown__menu">
          <ul class="u-bare-list u-font-size-large">
            <li
              v-if="enableEmptySelection"
              class="c-dropdown__menu-item"
              @click="selectItem('')"
            >
              <a
                class="u-text-nounderline"
              >
                {{ emptyTitle }}
              </a>
            </li>
            <li
              v-for="(item, index) in items"
              :key="index"
              :class="{
                'is-active': activeItem ? item.id == activeItem.id : false,
                'u-font-italic': item.isMarked
              }"
              class="c-dropdown__menu-item"
              @click="selectItem(item)"
            >
              <a
                class="u-text-nounderline"
              >
                <span
                  class="u-flex u-flex-1"
                  v-html="item.name"
                />
                <template v-if="item.icon">
                  <Icon
                    v-if="item.icon.stroke"
                    class="u-ml--a"
                    :icon="item.icon.icon"
                    :size="item.icon.size"
                    :stroke="item.icon.stroke"
                  />
                  <Icon
                    v-else
                    class="u-ml--a"
                    :icon="item.icon.icon"
                    :size="item.icon.size"
                    :fill="item.icon.fill"
                  />
                </template>
              </a>
            </li>
          </ul>
        </div>
      </template>
    </Popover>
    <span
      v-if="$mq != 'desktop'"
      class="c-dropdown__text u-flex u-flex-align-center"
      :class="{
        'u-font-size-medium': size === 'small'
      }"
      @click.prevent="toggleDropdown"
    >
      <span
        :class="{
          'u-font-italic': activeItem && activeItem.isMarked,
          'u-mr--xxs': smallArrow,
          'u-mr--s': !smallArrow
        }"
      >
        {{ activeName }}
      </span>
      <Icon
        class="u-ml--a"
        :icon="iconArrowDown"
        :size="arrowSize"
        :fill="iconColor"
      />
    </span>
    <select
      v-if="$mq != 'desktop'"
      class="c-dropdown__select"
      @change="onSelectChange"
    >
      <option
        v-if="!activeItem && emptyTitle || enableEmptySelection"
        value=""
      >
        {{ emptyTitle }}
      </option>
      <option
        v-for="(item, index) in items"
        :key="index"
        :value="item.id"
        :selected="activeItem && activeItem.id == item.id"
        :class="{
          'u-font-italic': item.isMarked
        }"
      >
        {{ item.name }}
      </option>
    </select>
  </div>
</template>

<script>
import iconArrowDown from '@ds/svg/icons/bold/arrow-down-1-bold.svg'
import Popover from '@/CVI/WebCore/components/Popover.vue'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon,
    Popover
  },
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    size: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    emptyTitle: {
      type: String,
      default: ''
    },
    smallArrow: {
      type: Boolean,
      default: false
    },
    iconColor: {
      type: String,
      default: 'dark-gray'
    },
    container: {
      type: String,
      default: 'body'
    },
    enableEmptySelection: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconArrowDown,
      isDropdownVisible: false
    }
  },
  computed: {
    activeItem() {
      return this.items.find(item => item.id == this.value)
    },
    activeName() {
      const { activeItem, emptyTitle } = this
      if (activeItem) {
        return activeItem.name
      }
      return emptyTitle || ''
    },
    classes() {
      const classes = []
      if (this.size == 'small') {
        classes.push('is-small')
      }
      return classes
    },
    arrowSize() {
      if (this.smallArrow) {
        return 12
      }
      return this.size === 'small' ? 12 : 16
    }
  },
  methods: {
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible
    },
    selectItem(item) {
      this.$emit('input', item?.id)
      this.$emit('on-click', item?.id)
      this.isDropdownVisible = false
    },
    onSelectChange(event) {
      const { value } = event.target
      const selectedItem = this.items.find(item => item.id == value)
      this.selectItem(selectedItem)
    }
  }
}
</script>
