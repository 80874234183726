var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-dropdown",class:_vm.classes},[(_vm.$mq == 'desktop')?_c('Popover',{attrs:{"placement":'bottom-start',"offset":2,"auto-close":true,"auto-close-delay":100000,"container":_vm.container,"dropdown-class":"c-dropdown__dropdown"},model:{value:(_vm.isDropdownVisible),callback:function ($$v) {_vm.isDropdownVisible=$$v},expression:"isDropdownVisible"}},[_c('span',{staticClass:"c-dropdown__text u-flex u-flex-align-center",class:{
        'u-font-size-medium': _vm.size === 'small'
      },on:{"click":function($event){$event.preventDefault();return _vm.toggleDropdown($event)}}},[_c('span',{class:{
          'u-font-italic': _vm.activeItem && _vm.activeItem.isMarked,
          'u-mr--xxs': _vm.smallArrow,
          'u-mr--s': !_vm.smallArrow
        }},[_vm._v(" "+_vm._s(_vm.activeName)+" ")]),_c('Icon',{staticClass:"u-ml--a",attrs:{"icon":_vm.iconArrowDown,"size":_vm.arrowSize,"fill":_vm.iconColor}})],1),_c('template',{slot:"popover"},[_c('div',{staticClass:"c-dropdown__menu"},[_c('ul',{staticClass:"u-bare-list u-font-size-large"},[(_vm.enableEmptySelection)?_c('li',{staticClass:"c-dropdown__menu-item",on:{"click":function($event){return _vm.selectItem('')}}},[_c('a',{staticClass:"u-text-nounderline"},[_vm._v(" "+_vm._s(_vm.emptyTitle)+" ")])]):_vm._e(),_vm._l((_vm.items),function(item,index){return _c('li',{key:index,staticClass:"c-dropdown__menu-item",class:{
              'is-active': _vm.activeItem ? item.id == _vm.activeItem.id : false,
              'u-font-italic': item.isMarked
            },on:{"click":function($event){return _vm.selectItem(item)}}},[_c('a',{staticClass:"u-text-nounderline"},[_c('span',{staticClass:"u-flex u-flex-1",domProps:{"innerHTML":_vm._s(item.name)}}),(item.icon)?[(item.icon.stroke)?_c('Icon',{staticClass:"u-ml--a",attrs:{"icon":item.icon.icon,"size":item.icon.size,"stroke":item.icon.stroke}}):_c('Icon',{staticClass:"u-ml--a",attrs:{"icon":item.icon.icon,"size":item.icon.size,"fill":item.icon.fill}})]:_vm._e()],2)])})],2)])])],2):_vm._e(),(_vm.$mq != 'desktop')?_c('span',{staticClass:"c-dropdown__text u-flex u-flex-align-center",class:{
      'u-font-size-medium': _vm.size === 'small'
    },on:{"click":function($event){$event.preventDefault();return _vm.toggleDropdown($event)}}},[_c('span',{class:{
        'u-font-italic': _vm.activeItem && _vm.activeItem.isMarked,
        'u-mr--xxs': _vm.smallArrow,
        'u-mr--s': !_vm.smallArrow
      }},[_vm._v(" "+_vm._s(_vm.activeName)+" ")]),_c('Icon',{staticClass:"u-ml--a",attrs:{"icon":_vm.iconArrowDown,"size":_vm.arrowSize,"fill":_vm.iconColor}})],1):_vm._e(),(_vm.$mq != 'desktop')?_c('select',{staticClass:"c-dropdown__select",on:{"change":_vm.onSelectChange}},[(!_vm.activeItem && _vm.emptyTitle || _vm.enableEmptySelection)?_c('option',{attrs:{"value":""}},[_vm._v(" "+_vm._s(_vm.emptyTitle)+" ")]):_vm._e(),_vm._l((_vm.items),function(item,index){return _c('option',{key:index,class:{
        'u-font-italic': item.isMarked
      },domProps:{"value":item.id,"selected":_vm.activeItem && _vm.activeItem.id == item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }